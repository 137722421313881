import React, { useEffect, useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"

import instance from "../../components/api/httpclient";
import { navigate, useIntl, FormattedDate, Link } from "gatsby-plugin-intl";
import moment from "moment";
import GenericVehicle from "../../assets/images/vehicle/generic.png";
import iconInfo from "../../assets/images/item-info.svg";
import ReactImageFallback from "react-image-fallback";
import ENV from "../../../env";

class BodyClass extends React.Component {
    componentDidMount() {
        document.body.classList.add(this.props.name);
    }
    componentWillUnmount() {
        document.body.classList.remove(this.props.name);
    }
    render() {
        return this.props.children || null;
    }
}

const Timeline = () => {
  const intl = useIntl();
  const [car, setCar] = useState('');
  const [model, setModel] = useState('');
  const [reminders, setReminders] = useState([]);
  const [lastUpdateDate, setLastUpdateDate] = useState('2020-02-20T08:56:20+00:00');
  const [allEvents, setAllEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [banners, setBanners] = useState([]);
  const [currentCars, setCurrentCars] = useState([]);
  const [currentDealer, setCurrentDealer] = useState([]);
  const [selectedCar, setSelectedCar] = useState('');
  const [selectedWhen, setSelectedWhen] = useState(
    {
    value: 'next',
    name: intl.formatMessage({ id: "timeline.next_events" })
  });
  const [times, setTimes] = useState([
    {
      value: 'next',
      name: intl.formatMessage({ id: "timeline.next_events" })
    },
    {
      value: 'past',
      name: intl.formatMessage({ id: "timeline.past_events" })
    }
  ]);
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const [currentUserStatus, setCurrentUserStatus] = useState(false);
  const [levelUpScreenOpened, setLevelUpScreenOpened] = useState(false);
  const currentUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : {};

    useEffect(() => {
        // LOADING USER DATA
        instance.get("/me",
            {
                headers: {
                    'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                }
            })
            .then((res) => {

                if (res.status === 200) {
                    if (typeof window !== 'undefined') {
                        localStorage.setItem('currentUser', JSON.stringify(res.data.user));

                        setCurrentUserStatus(res.data.user.status)
                        setTooltipOpened(!res.data.user.welcomeTimeline);

                        if (0 === res.data.user.cars.length) {
                          navigate('/maintenance/timeline-add-car');
                        }
                        setLevelUpScreenOpened(res.data.user.levelUpNotificationScreen);

                        const nbEvents = res.data.user.events.length;
                        var events = Array();
                        var eventReminders = Array();
                        localStorage.setItem('currentEvents', JSON.stringify(events));
                        for (let i = 0; i < nbEvents; i++) {
                            instance.get(res.data.user.events[i], {
                                headers: {
                                    'Authorization': typeof window
                                        !== 'undefined' ? localStorage.getItem('token') : ''
                                }
                            }).then((e) => {
                                if (e.status === 200) {
                                    if (typeof window !== 'undefined') {
                                        //console.log('response event', e);
                                        events.push(e.data);
                                        if (!e.data.done && !e.data.reminderSeen && moment(new Date()).diff(e.data.reminderDate, 'days') >= 0  || e.type !== 99) {
                                            eventReminders.push(e.data);
                                        }

                                        setReminders(eventReminders);
                                        localStorage.setItem('currentEvents', JSON.stringify(events));
                                    }
                                }
                            }).catch((error) => {
                                //console.log('error', error)
                            });
                        }
                        // Update levelScreen
                        instance.get(`/update-level-notification/`+currentUser.id,
                            {
                                headers: {
                                    'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                                }
                            })
                        // Dealer
                        instance.get(res.data.user.dealer,
                            {
                                headers: {
                                    'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                                }
                            })
                            .then((r) => {
                                if (r.status === 200) {
                                    if (typeof window !== 'undefined') {
                                        localStorage.setItem('currentDealer', JSON.stringify({
                                            id: r.data.id,
                                            name: r.data.name,
                                            address: r.data.address,
                                            postcode: r.data.postcode,
                                            city: r.data.city,
                                            phone: r.data.phone,
                                            bookingUrl: r.data.bookingUrl
                                        }));

                                        setCurrentDealer(r.data);
                                    }
                                }
                            }).catch( (error) => {
                            console.log('error', error)
                          });
                        // Menu Cars
                        instance.get('/my-cars',
                            {
                                headers: {
                                    'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                                }
                            })
                            .then((r) => {
                                localStorage.setItem('currentCars', JSON.stringify(r.data));
                                setCurrentCars(r.data)
                                setSelectedCar(r.data[0] ?? null)
                                let firstCarId = r.data[0] ? r.data[0]['id'] : null;
                                if (null !== firstCarId) {
                                    getMyTimeline(firstCarId, selectedWhen.value);
                                }
                            }).catch( (error) => {
                            console.log('error', error)
                          });
                }
              }
              else if (res.status === 401) {
                navigate('/sessions/logout');
              }
            }
          ).catch( (error) => {
            //console.log('error', error);
            navigate('/sessions/logout');
          }
        )
      }, []);

    const getMyTimeline = (carId, when) => {
        if (carId !== undefined && selectedWhen.value === when) {
            instance.get('/timeline/car/' + carId,
                {
                    params: {locale: intl.locale},
                    headers: {
                        'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                    }
                })
                .then((response) => {
                    setAllEvents(response.data.events);
                    setBanners(response.data.banners);
                    setEvents(response.data.events[when]);
                    if (response.data.events['past'].length === 0) {
                        setLastUpdateDate(selectedCar.firstRegistrationDate);
                    } else {
                        setLastUpdateDate(selectedCar.lastMaintenanceDate)
                    }
                })
                .catch((error) => {
                    //console.log('error', error);
                })
        } else {
            setEvents(allEvents[when]);
        }
    };

    const getIconCss = (eventType) => {

        switch (eventType) {
            case 1:
                return 'icon-calendar-maintenance';
            case 2:
                return 'icon-controle-technique';
            case 3:
                return 'icon-tire';
            case 4:
                return 'icon-tire';
            case 5:
                return 'icon-extension-garanti';
            case 6:
                return 'icon-contracts';
            case 7:
                return 'icon-assistance';
            case 8:
                return 'icon-card';
            case 9:
                return '';
        }
    }

    const openRewardTooltip = (e) => {
        let rewardTooltipDiv = e.target.closest('.event').getElementsByClassName('reward-tooltip')[0];
        if (rewardTooltipDiv) {
            if (rewardTooltipDiv.classList.contains('d-none')) {
                rewardTooltipDiv.classList.remove('d-none');
            } else {
                rewardTooltipDiv.classList.add('d-none');
            }
        }

        let rewardTooltipTimelineDiv =e.target.closest('.event').getElementsByClassName('tooltip-timeline')[0];
        if (rewardTooltipTimelineDiv) {
            if (rewardTooltipTimelineDiv.classList.contains('d-none')) {
                rewardTooltipTimelineDiv.classList.remove('d-none');
            } else {
                rewardTooltipTimelineDiv.classList.add('d-none');
            }
        }
    }

    const closeToolTip = (e) => {
        let eventDiv = e.target.closest('.tooltip-custom');
        if (eventDiv) {
            if (eventDiv.classList.contains('d-none')) {
                eventDiv.classList.remove('d-none');
            } else {
                eventDiv.classList.add('d-none');
            }
        }
    }

    const getFooter = () => {
        if (true !== selectedCar.isLeasePlan) {
            return (
                <footer className="d-flex align-items-center px-0 py-3">
                    <div className="px-3 links-contact-go border-r">
                        {
                            currentDealer.bookingUrl &&
                            (
                                <a href={currentDealer.bookingUrl} target="_blank" className="d-flex align-items-center"><span
                                    className="icon icon-calendar"></span>{intl.formatMessage({
                                        id:
                                            "timeline.buttons.button_book_dealer"
                                    })} <span></span></a>
                            )
                        }
                        {(!currentDealer.bookingUrl && currentDealer.address) &&
                            (
                                <a className="d-flex align-items-center" href={encodeURI("https://www.google.com/maps/search/" + currentDealer.name + ' ' + currentDealer.address + ' ' + currentDealer.postcode + ' ' + currentDealer.city)} target="_blank"><span className="icon icon-pin-marker"></span>{intl.formatMessage({ id: "timeline.buttons.button_visit_dealer" })} <span></span></a>
                            )
                        }
                    </div>
                    <div className="px-3 links-contact-call">
                        {currentDealer.phone &&
                            (
                                <a href={encodeURI("tel:" + currentDealer.phone)} target="_blank" className="d-flex align-items-center"><span className="icon icon-phone"></span> <span>{intl.formatMessage({ id: "timeline.buttons.button_call_dealer" })}</span></a>
                            )
                        }
                    </div>
                </footer>
            )
        }
    }

    const getEvents = () => {
        if (events && events.length > 0) {
            return (
                <div className="timeline-container">
                    {events.map(eve => (
                        <div className="event" key={eve.id}>
                            <div className="event-icon">
                                <span className={getIconCss(eve.type)}></span>
                                {eve.done &&
                                    (
                                        <span className="icon-check alert-icon"></span>
                                    )
                                }
                                {!eve.done && (moment(new Date()).diff(eve.dueDate, ' days') >= 0) &&
                                    (
                                        <span className="icon-time alert-icon"></span>
                                    )
                                }
                            </div>
                            <div className="container-event position-relative">
                                <header className="pl-4">
                                    <h3 className="mb-1">{intl.formatMessage({ id: "timeline.event." + eve.type + ".title" })}</h3>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-0">
                                            <FormattedDate value={eve.validationDate ?? eve.dueDate} day="2-digit" month="long" year="numeric" />
                                        </h4>
                                        {!eve.done && (moment(new Date()).diff(eve.dueDate, 'months') >= 2 && moment(new Date()).diff(eve.dueDate,'months') <= 3) && (
                                            <div className="bg-dark-red white rounded ml-3 px-2">
                                                <span>2 {intl.formatMessage({id: "timeline.mentions.months_late"})}</span>
                                            </div>
                                        )}
                                    </div>
                                </header>
                                <div className="text">
                                    {intl.formatMessage({ id: "timeline.event." + eve.type + ".description" })}
                                </div>
                                {eve.rewardPoints > 0 &&
                                    <div className="tooltip-timeline tooltip-custom availability-tooltip dark-red-border mb-4 my-3 mx-3 mx-sm-0 mt-1 d-none">
                                        <a href="javascript:void(0);" className="close" onClick={e => closeToolTip(e)}><span>+</span></a>
                                        <p className="dark-red pr-3">
                                            {eve.done === false &&
                                                <span>&nbsp;{intl.formatMessage({id: "rewards_history.reward_points_event.3"}, {'points': eve.rewardPoints})}</span>
                                            }
                                            {eve.done &&
                                                <span>&nbsp;{intl.formatMessage({id: "rewards_history.reward_points_event.1"}, {'points': eve.rewardPoints})}</span>
                                            }
                                            {eve.offer &&
                                                <span>&nbsp;{intl.formatMessage({id: "rewards_history.reward_points_event.2"}, {'offer':  intl.formatMessage({id: eve.offer }) })}</span>
                                            }
                                        </p>
                                    </div>
                                }
                                {eve.rewardPoints > 0 &&
                                    <header className="rounded-0 py-0 px-4">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center"><span
                                                className="icon-cup mr-3"></span>
                                                <div>
                                                    <h3 className="mb-0">{eve.rewardPoints} {intl.formatMessage({id: "timeline.points"})}</h3>
                                                    {eve.offer &&
                                                        <h4 className="mb-0">{intl.formatMessage({id: eve.offer})}</h4>
                                                    }
                                                </div>
                                            </div>
                                            <a className="d-flex link-info-reward" href="javascript:void(0);" onClick={e => openRewardTooltip(e)}>
                                                <img src={iconInfo} alt="info" width={"30px"}/>
                                            </a>
                                        </div>
                                    </header>
                                }
                                {getFooter()}
                                { selectedWhen.value === 'next' && moment(new Date()).diff(eve.dueDate,'months') <= 3  && new Date().getFullYear() - (eve.dueDate).slice(0, 4) >= 0 &&
                                    <div
                                        className="tooltip-custom dark-red dark-red-border text-center mt-3 mx-3 mx-sm-0 mt-lg-2 reward-tooltip d-none">
                                        <a href="#" className="close"><span>+</span></a>
                                        <h3 className="text dark-red mb-2 p-0 text-left">
                                            <span className="icon-questions align-middle fs-20 mr-2"></span>
                                            <strong>Rewards</strong>
                                        </h3>
                                        <p className="dark-red text-left mb-4 ">Completing all planned events will
                                            improve your You+NISSAN status, giving you access to exclusive rewards.</p>
                                        <Link to="/rewards/reward-list" className="button red no-chevron">
                                            <span className="icon-reward mr-3"></span>
                                            <span>Check my rewards</span>
                                        </Link>
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            );
        }
    };

    const getBanners = () => {
        if (banners && banners.length > 0) {
            return (
                <div className="banners-container">
                    {banners.map(banner => (
                        <div className="banner-wrapper mb-4">
                            <div className="banner-body">
                                {banner.image &&
                                    <img src={ENV.API_URL + banner.image} alt={banner.title}
                                         className="img-fluid align-middle"/>
                                }
                                <div className="banner-content">
                                    <span className="banner-title">{banner.title}</span>
                                    <div className="banner-desc">{banner.body}</div>
                                </div>
                            </div>
                            {banner.ctaUrl &&
                                <a href={banner.ctaUrl} target="_blank" className="button mx-auto">
                                    {banner.ctaLabel}
                                </a>
                            }
                        </div>
                    ))}
                </div>
            );
        }
    };

    const getCars = () => {
        if (currentCars.length > 0 && selectedCar !== undefined) {
            return (

                <div className="dropdown">
                    <a className="dropdown-toggle text darker-grey" href="#" role="button" id="dropdownMenuLink"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <ReactImageFallback src={selectedCar.image} fallbackImage={GenericVehicle}
                            alt={selectedCar.name} className="img-fluid align-middle" width="50" />
                        <span className="d-none d-md-inline ml-3"><strong>{selectedCar.name}</strong> {selectedCar.licencePlate}</span>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        {currentCars.map(car => (
                            <a className="dropdown-item mb-2 mb-lg-1" href="" key={car.id} onClick={e => selectCar(car,
                                e)}><strong>{car.name}</strong> {car.licencePlate}</a>
                        ))}
                    </div>
                </div>
            );
        }
    };

    const getTimes = () => {
        return (
            <div className="dropdown">
                <a className="dropdown-toggle text darker-grey" href="" role="button" key={selectedWhen.value}
                    id="dropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <strong>{selectedWhen.name}</strong>
                </a>

                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink2">
                    {times.map(time => (
                        <a className="dropdown-item mb-2 mb-lg-1" href="" key={time.value} onClick={e => selectWhen(time,
                            e)}>{time.name}</a>
                    ))}
                </div>
            </div>
        );
    };

    const getReminder = () => {
        if (reminders.length > 0) {
            return (
                <div>
                    {reminders.map(reminder => (

                        <div className="tooltip-custom-event dark-red-border mb-4 mt-3 mx-3 mx-sm-0 mt-lg-0"
                            key={reminder.id}>

                            <a href="#" className="close" onClick={(e) => closeReminder(e, reminder.id)}><span>+</span></a>

                            <h3 className="white"><span className="icon-questions align-middle fs-20 mr-2"></span>
                                {intl.formatMessage({ id: "timeline.reminder.title" })} : </h3>

                            <p className="white mb-0">
                                {intl.formatMessage({ id: "timeline.reminder." + reminder.type + ".message" })}&nbsp;
                                <FormattedDate value={reminder.dueDate} day="2-digit" month="long" year="numeric" />.
                            </p>

                        </div>
                    ))}
                </div>
            )
        }
    };

    const selectCar = (car, e) => {
        setSelectedCar(car);
        e.preventDefault();
        localStorage.setItem('currentCar', JSON.stringify(car));

        getMyTimeline(car.id, selectedWhen.value);
        content();
    };

    const selectWhen = (when, e) => {
        setSelectedWhen(when);

        e.preventDefault();

        getMyTimeline(selectedCar.id, when.value);
        content();
    };

    const closeTooltip = () => {

        instance.post('/welcome',
            {
                type: 'timeline'
            },
            {
                headers: {
                    'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                }
            }).then(function (response) {
                if (typeof window !== 'undefined') {
                    localStorage.setItem('currentUser', JSON.stringify(response.data.user));
                }
            });

        setTooltipOpened(false);
    };

    const closeReminder = (e, eventId) => {
        if (eventId !== undefined) {
            instance.get('/timeline/event/seen/' + eventId,
                {
                    headers: {
                        'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                    }
                })
                .then((response) => {

                })
                .catch((error) => {
                    console.log('error', error);
                })
        }
        e.preventDefault();
        e.target.parentNode.parentNode.remove();
    }

    const needToUpdateCar = () => {
        let now = moment(new Date());
        let lastUpdatedDate = moment(selectedCar.updatedDate);

        if (now.diff(lastUpdatedDate, 'months') > 6) {
            return (
                <div className="message-important mb-4">


                    <p>{intl.formatMessage({ id: "timeline.notice_box.1.description" })}</p>

                    <div className="text-center">
                        <Link to="/mycars/my-cars/" state={{ id: selectedCar.id }} className="button mx-auto with-icon">
                            <span
                                className="icon-pencil icon"></span>{intl.formatMessage({ id: "timeline.notice_box.1.button" })}
                        </Link>
                    </div>

                </div>
            )
        } else {
            return '';
        }
    };

  const getRewardIconCss = (eventLevel) => {
    switch(parseInt(eventLevel)) {
      case 1:
        return 'bronze';
      case 2:
        return 'silver';
      case 3:
        return 'gold';
      case 4:
        return 'platinum';
    }
  }
  const content = () => {
    return(
      <div className={levelUpScreenOpened ? 'col-lg-12' : 'col-lg-8'}>
        {levelUpScreenOpened &&
            <div className="bg-white pb-5">
                <div className="container win-trophy">
                    <h1>{intl.formatMessage({ id: "pages_meta_title.profile.congratulations" })}</h1>
                    <div className="light-grey">
                        <span className={`icon-win icon-win-text ${getRewardIconCss(currentUserStatus)}`}>
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                        </span>
                    </div>

                    <p className="like-h1" dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "welcome.level_up.title" }, {class: getRewardIconCss(currentUserStatus), level: getRewardIconCss(currentUserStatus).toUpperCase()})}} />
                    <p className="text-center">{intl.formatMessage({ id: "welcome.level_up.body" })}</p>
                    <div className="text-center">
                        <Link className="button red no-chevron" to="/rewards/reward-list">
                            <span className="icon-reward mr-3"></span>
                            <span>{intl.formatMessage({ id: "welcome.level_up.link" })}</span>
                        </Link>
                    </div>
                </div>
            </div>
        }
        {!levelUpScreenOpened &&
          <div>
            {tooltipOpened &&

              <div className="tooltip-custom dark-red-border mb-4 mt-3 mx-3 mx-sm-0 mt-lg-0">

                <div className="close" onClick={() => closeTooltip()}><span>+</span></div>

                <h3 className="dark-red"><span
                    className="icon-questions align-middle fs-20 mr-2"></span>{intl.formatMessage({id: "help_bubulle.welcome.title"})}
                </h3>

                <p className="dark-red mb-0">{intl.formatMessage({id: "help_bubulle.welcome.description"})}</p>

              </div>
            }
            {getReminder()}
            <div className="white-container">

            <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{intl.formatMessage({id:"timeline.title"})}</h1>
            <p>{intl.formatMessage({id:"timeline.description"})}</p>

            <div className="d-flex align-items-center justify-content-between">
              {getCars()}
              {getTimes()}
            </div>
            <hr className="mt-2" />
            {needToUpdateCar()}
            {getBanners()}
            {getEvents()}
            </div>
          </div>
        }
      </div>
    )
  };

    return (
        <Layout>
            <SEO title={intl.formatMessage({ id: "pages_meta_title.maintenance.timeline" })} />
            <BodyClass name="large-wrapper" />

      <MediaLayout children={content()} currentPage="timeline" showLeftMenu={!levelUpScreenOpened} showTopMenu={!levelUpScreenOpened} />

            <Link to="/contact/how-can-help-you" className="btn-contact"><span
                className="icon-questions"></span></Link>

        </Layout>
    )
};

export default Timeline
